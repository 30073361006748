import type { LocationRepository } from '@/core/location'
import type { ApiChargersResponse, ApiGetLocationResponse, ApiLocationsLocationApiResponse } from './types'
import { endpoints } from '@/core/shared/endpoints.config'
import ENV from '@/engine/env/web.env'
import { ulid } from 'ulid'
import type { RepositoryHttpBuild } from '@/core/shared'
import { provideAuthUseCases } from '@/core/auth'

export const perseusLocationRepositoryBuilder: RepositoryHttpBuild<LocationRepository> = ({ httpService }) => {
  const perseusInstance = httpService.create({
    baseURL: ENV.api.perseusApiBaseUrl,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  const wapiInstance = httpService.create({
    baseURL: ENV.api.baseURL,
    options: {
      auth: ENV.api.authSystem === 'cookie' ? httpService.AUTH_METHODS.COOKIE : httpService.AUTH_METHODS.LOCAL_STORAGE,
      onLogout: () => {
        sessionStorage.removeItem('wb-state')
        localStorage.removeItem('wb-state')
        window.location.href = '/login'
      },
      onRefreshToken: async () => {
        const result = await provideAuthUseCases().doRefreshToken()
        return {
          token: result.token,
          refreshToken: result.refreshToken
        }
      }
    }
  })

  return {
    async getAllLocations (organizationUid: string, params) {
      const locations = await wapiInstance.get<ApiLocationsLocationApiResponse>(
        endpoints.v4.get_locations.replace('{organizationUid}', organizationUid || '-1'), {
          params: {
            filters: params.filters && JSON.stringify({ filters: params.filters })
          }
        })

      if (!locations) {
        return []
      }

      let chargers: ApiChargersResponse['data'] = []

      if (params.getChargers) {
        chargers = (await perseusInstance.get<ApiChargersResponse>(
          '/organizations/{organizationId}/chargers'.replace('{organizationId}', organizationUid),
          {
            params: {
              limit: 5000,
              offset: 0
            }
          }
        ))?.data ?? []
      }

      const locationWithChargers = locations.data.map(location => {
        return {
          id: location.id,
          name: location.attributes.name,
          address: location.attributes.address ?? undefined,
          energyType: location.attributes.energy_type ?? undefined,
          locationTypes: location.attributes.location_types ? location.attributes.location_types.split(',') : [],
          instructions: location.attributes.instructions ?? undefined,
          city: location.attributes.city ?? undefined,
          state: location.attributes.state ?? undefined,
          zipCode: location.attributes.zip_code ?? undefined,
          country: location.attributes.country,
          latitude: location.attributes.latitude ?? undefined,
          longitude: location.attributes.longitude ?? undefined,
          energyCost: location.attributes.energy_cost,
          groupUid: location.attributes.group_uid,
          organizationId: location.attributes.space_uid,
          chargers: chargers
            .filter(charger => charger.attributes.location_id === location.id)?.map(charger => ({
              id: +charger.attributes.serial_number,
              uid: charger.id,
              name: charger.attributes.name
            }))
        }
      })

      return locationWithChargers
    },

    async getLocation (locationId: string) {
      const result = await wapiInstance
        .get<ApiGetLocationResponse>(endpoints.v4.location.replace('{locationUid}', locationId || '-1'))

      const location = result?.data

      if (!location) return undefined

      return {
        id: locationId,
        name: location.attributes.name,
        address: location.attributes.address ?? undefined,
        energyType: location.attributes.energy_type ?? undefined,
        locationTypes: location.attributes.location_types ? location.attributes.location_types.split(',') : [],
        instructions: location.attributes.instructions ?? undefined,
        city: location.attributes.city ?? undefined,
        state: location.attributes.state ?? undefined,
        zipCode: location.attributes.zip_code ?? undefined,
        country: location.attributes.country,
        latitude: location.attributes.latitude ?? undefined,
        longitude: location.attributes.longitude ?? undefined,
        energyCost: location.attributes.energy_cost,
        groupUid: location.attributes.group_uid,
        organizationId: location.attributes.space_uid
      }
    },

    async editLocation (location) {
      await wapiInstance.put(endpoints.v4.location.replace('{locationUid}', location.id), {
        data: {
          type: 'location',
          attributes: {
            name: location.name,
            address: location.address,
            energy_type: location.energyType,
            location_types: (location.locationTypes?.length ?? 0) > 0 ? location.locationTypes?.join() : null,
            instructions: location.instructions,
            city: location.city,
            state: location.state,
            zip_code: location.zipCode,
            country: location.country,
            latitude: location.latitude,
            longitude: location.longitude,
            energy_cost: location.energyCost
          }
        }
      })
    },

    async createLocation (location) {
      const id = ulid()

      await wapiInstance.post(endpoints.v4.locations, {
        data: {
          type: 'location',
          id,
          attributes: {
            space_uid: location.organizationId,
            group_uid: ulid(),
            name: location.name,
            location_uid: id,
            energy_cost: location.energyCost,
            location_types: (location.locationTypes?.length ?? 0) > 0 ? location.locationTypes?.join() : null,
            energy_type: location.energyType,
            instructions: location.instructions,
            address: location.address,
            city: location.city,
            state: location.state,
            zip_code: location.zipCode,
            country: location.country,
            latitude: location.latitude,
            longitude: location.longitude
          }
        }
      })

      return id
    },

    async deleteLocation (locationId: string) {
      await wapiInstance.delete(endpoints.v4.location.replace('{locationUid}', locationId))
    }
  }
}
